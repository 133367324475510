<template>
  <div class="voucher_subject_view">
    <div class="content-select">
      <div class="search_box">
        <el-form ref="filterForm" :model="queryParams" inline label-width="80px">
          <el-row class="row-first">
            <el-form-item label="科目编码" prop="accountCode">
              <el-input v-model="queryParams.accountCode" placeholder="请输入" clearable />
            </el-form-item>
            <el-form-item label="科目名称" prop="accountName">
              <el-input v-model="queryParams.accountName" placeholder="请输入" clearable @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleQuery">查询</el-button>
              <el-button type="default" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="btn_box">
        <!-- <el-button type="primary" @click="handleImport">导入</el-button> -->
        <el-button type="primary" @click="handleUpdate">同步科目</el-button>
      </div>
      <div class="content-table">
        <el-table :data="records" ref="table" stripe :header-cell-style="handleHeaderCellStyle" border v-loading="loading" style="width: 100%">
          <el-table-column label="序号" type="index" />
          <el-table-column label="科目编码" style="height: 100px">
            <template slot-scope="scope">
              {{ scope.row.accountCode }}
              <el-tree
                v-if="scope.row.children"
                :data="scope.row.children"
                :props="{
                  children: 'children',
                  label: 'accountCode'
                }"
              ></el-tree>
            </template>
          </el-table-column>
          <el-table-column label="科目名称" prop="name" />
          <el-table-column label="科目全称" prop="accountFullName" />
          <el-table-column label="方向" prop="accountDirection">
            <template slot-scope="scope">{{ scope.row.accountDirection ? '借' : '贷' }}</template>
          </el-table-column>
          <el-table-column label="科目类别" prop="accountType" :show-overflow-tooltip="true" />
          <el-table-column label="辅助核算">
            <template slot-scope="scope">
              {{ scope.row.accountingSupplier ? '供应商' : '' }}
              {{ scope.row.accountingClients ? '客户' : '' }}
              {{ scope.row.accountingProjects ? '项目' : '' }}
            </template>
          </el-table-column>
          <el-table-column label="同步状态" prop="pushStatus" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.pushStatus == 0 ? '未同步' : '' }}
              {{ scope.row.pushStatus == 1 ? '同步中' : '' }}
              {{ scope.row.pushStatus == 2 ? '已同步' : '' }}
              {{ scope.row.pushStatus == 3 ? '同步失败' : '' }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="handleTableOpt(scope.row, 'add')">新增下级</el-button>
              <el-button size="small" type="text" @click="handleTableOpt(scope.row, 'edit')">编辑</el-button>
              <el-button size="small" type="text" @click="handleTableOpt(scope.row, 'del')">删除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[15, 30, 50, 100]" :page-size="queryParams.size" :current-page="queryParams.page" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>

    <el-dialog width="30%" append-to-body :visible.sync="visiable" title="导入">
      <div style="margin: 10px 20px">导入</div>
      <div slot="footer" class="button-footer" style="text-align: center">
        <el-button type="default" @click="visiable = false" class="button-left">取消</el-button>
        <el-button type="primary" @click="handleDialog">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableStyle } from '@/util/mixins';
import { getList, syncAccounts } from '@/service/voucher';

export default {
  name: 'VouKmgl',
  mixins: [tableStyle],
  data() {
    return {
      // 查询参数
      queryParams: {
        page: 1,
        size: 15,
        accountName: '',
        accountCode: ''
      },
      records: [],
      loading: false,
      total: 0,
      paging: {
        total: 0,
        size: 15,
        current: 1
      },
      pageSizes: [15, 30, 50, 100],
      visiable: false
    };
  },

  created() {
    this.getList();
  },
  methods: {
    /** 查询登录日志 */
    getList() {
      this.loading = true;
      getList(this.queryParams).then(({ success, data }) => {
        this.loading = false;
        // const { total } = data;
        if (success) {
          this.records = JSON.parse(data);
          this.total = this.records[0].total;
        }
      });
    },
    /** 搜索 */
    handleQuery() {
      this.getList();
    },
    /** 重置 */
    resetQuery() {
      this.queryParams = {
        page: 1,
        size: 15,
        accountName: '',
        accountCode: ''
      };
      this.getList();
    },
    // 导入
    handleImport() {
      this.$message({ message: '恭喜你，这是一条成功消息', type: 'success' });
    },
    // 同步
    async handleUpdate() {
      const res = await syncAccounts();
      res.success && this.toast('操作成功', 'success');
      this.getList();
    },
    // 操作
    handleTableOpt(row, type) {
      console.log(row, type);
    },
    handleSizeChange(val) {
      this.queryParams.page = 1;
      this.queryParams.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getList();
    },
    // 确认
    handleDialog() {
      this.$confirm('哈哈哈哈', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {})
        .catch(() => {
          this.toast('取消清空', 'warning');
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.voucher_subject_view {
  .search_box {
    padding: 20px 0 5px 20px;
  }
  .btn_box {
    padding-bottom: 10px;
  }
  .el-button--text {
    padding: 0 !important;
  }
}
</style>
<style lang="scss">
.voucher_subject_view {
  .el-table .cell {
    white-space: pre-line;
    height: auto !important;
    line-height: auto !important;
  }
}
</style>
